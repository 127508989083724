<template>
    <v-container>
        <v-card
            elevation="0"
            class="ma-0 pa-0"
        >
            <v-card-title>
                Alta de Perfiles
            </v-card-title>
            <v-divider class="mx-4"></v-divider>
            <validation-observer
                ref="observer"
                v-slot="{ invalid }"
            >
                <v-form @submit.prevent="submit" autocomplete="off">
                    <v-card-text>
                        <validation-provider
                            v-slot="{ errors }"
                            name="País"
                            rules="required|min:4|max:50"
                        >
                            <v-text-field
                            v-model="perfil"
                            :counter="50"
                            :error-messages="errors"
                            label="Perfil"
                            clearable
                            autofocus
                            autocomplete="off"
                            ></v-text-field>
                        </validation-provider>
                    </v-card-text>
                    <v-card-actions>
                        <v-row>
                            <v-col>
                                <v-btn
                                    class="mr-4"
                                    type="submit"
                                    block
                                    plain
                                    :disabled="invalid"
                                >
                                    Guardar
                                </v-btn>
                            </v-col>
                            <v-col>
                                <v-btn 
                                    block 
                                    plain 
                                    @click="clear"
                                >
                                    Limpiar
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-actions>
                </v-form>
            </validation-observer>
        </v-card>
        <v-snackbar
            v-model="snackbar"
            :timeout="timeout"
            :color="snackbar_color"
            >
            <p class=" text-center">
                {{ text_snackbar }}
            </p>
        </v-snackbar>
    </v-container>
</template>

<script>
    import { required, max, min } from 'vee-validate/dist/rules'
    import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'
    import axios from "axios";

    setInteractionMode('aggressive')

    extend('required', {
    ...required,
    message: '{_field_} no puede quedar vacio',
    })

    extend('max', {
    ...max,
    message: '{_field_} no puede tener más de {length} caractéres',
    })

    extend('min', {
    ...min,
    message: '{_field_} no puede tener menos de {length} caractéres',
    })

    export default {
    components: {
        ValidationProvider,
        ValidationObserver,
    },
    data: () => ({
        snackbar: false,
        text_snackbar: '',
        timeout: 3000,
        snackbar_color: '',
        perfil: '',
    }),
    methods: {
        async submit () {
            try {
                this.$refs.observer.validate();
                var response = await axios({url: process.env.VUE_APP_API + "parametrizacion/perfil",  method: "POST", headers: { "Accept": "application/json", "Content-Type": "application/json;charset=UTF-8", 'Authorization': 'Bearer '+this.$session.get('jwt')}, data: { "txtDesc": this.perfil }});
                if(response.data.estado == "OK"){
                    this.snackbar_color = 'success';
                    this.text_snackbar = "Se agrego " + response.data.data.txtDesc.toUpperCase() + " como perfil.";
                    this.snackbar = true;
                    this.clear();
                }else{
                this.snackbar_color = 'warning';
                this.text_snackbar = response.data.data.error;
                this.snackbar = true;
                this.clear();
                }
            } catch (error) {
                this.snackbar_color = 'danger';
                this.text_snackbar = error;
                this.snackbar = true;
            }
        },
        clear () {
        this.perfil = '';
        this.$refs.observer.reset();
        },
    },
    }
</script>